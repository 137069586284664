<template>
  <div>
    <b-card no-body class="mb-0">
      <template #header>
        <b-button
          @click="openModal()"
          variant="outline-primary"
          class="mb-1 cols col-sm-12 col-md-4 col-lg-2"
        >
          New Ban
        </b-button>
        <filter-checkbox
          id="show-banned"
          class="mb-1 w-auto ml-md-1 mr-auto"
          label="Show banned"
          :value="filter.is_banned"
          @change="setFilter({ is_banned: $event })"
          :disabled="filter.is_whitelisted"
        />
        <filter-checkbox
          id="show-whitelisted"
          class="mb-1 w-auto mx-lg-auto"
          label="Show whitelisted"
          :value="filter.is_whitelisted"
          @change="setFilter({ is_whitelisted: $event })"
          :disabled="filter.is_banned"
        />
        <b-tooltip target="show-banned" variant="info" triggers="hover">
          List IPs that are banned
        </b-tooltip>
        <b-tooltip target="show-whitelisted" variant="info" triggers="hover">
          List IPs that are whitelisted
        </b-tooltip>
        <search-input @search="setFilter({ search: $event })"></search-input>
      </template>
      <table-spinner :loading="loading || stateLoading"></table-spinner>
      <b-table
        v-if="!loading && !stateLoading"
        id="bannedip-list"
        class="position-relative"
        :items="data"
        responsive
        primary-key="id"
        :fields="fields"
        show-empty
        empty-text="No matching records found"
      >
        <template #head()="fields">
          <table-header-labels
            :fields="fields"
            :filter="filter"
            @onSort="changePage($event)"
          ></table-header-labels>
        </template>
        <template #cell(ip)="data">
          <b-link
            :to="{ name: 'ip', params: { ip: data.item.ip } }"
            class="font-weight-bold d-block text-nowrap"
          >
            {{ data.item.ip }}
          </b-link>
        </template>
        <template #cell(is_banned)="data">
          <b-form-checkbox disabled :checked="data.value" />
        </template>
        <template #cell(is_whitelisted)="data">
          <b-form-checkbox disabled :checked="data.value" />
        </template>
        <template #cell(user_count)="data">
          <p>{{ data.item.users.length }}</p>
        </template>
        <template #cell(actions)="data">
          <b-dropdown
            :id="'dropdown-actions-' + data.item.id"
            text="Actions"
            class="m-md-2"
            variant="outline-primary"
          >
            <b-dropdown-item
              v-if="!data.item.is_banned && !data.item.is_whitelisted"
              @click="openModal(data.item.ip)"
              >Ban</b-dropdown-item
            >
            <b-dropdown-item
              v-if="!data.item.is_banned && !data.item.is_whitelisted"
              @click="openModal(data.item.ip, 'whitelisting')"
              >To whitelist</b-dropdown-item
            >
            <b-dropdown-item
              v-if="data.item.is_banned"
              @click="revertBan(data.item.ip)"
              >Revert ban</b-dropdown-item
            >
            <b-dropdown-item
              v-if="data.item.is_whitelisted"
              @click="revertWhitelist(data.item.ip)"
              >Revert whitelist</b-dropdown-item
            >
          </b-dropdown>
        </template>
      </b-table>
      <template #footer>
        <pagination
          :meta-data="meta"
          @change-page="setFilter({ page: $event })"
          @change-per-page="setFilter({ per_page: $event })"
        >
        </pagination>
      </template>
      <b-modal
        ref="ban-modal"
        cancel-variant="secondary"
        ok-title="Accept"
        cancel-title="Cancel"
        centered
        title="Add new ban"
        @ok="onSaveBan"
      >
        <b-form-group label="IP" label-for="ip">
          <b-form-input id="ip" v-model="payload.ip" />
        </b-form-group>
        <b-form-group label="Reason" label-for="reason">
          <b-form-input id="reason" v-model="payload.reason" />
        </b-form-group>
      </b-modal>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BTable,
  BButton,
  BModal,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BDropdown,
  BDropdownItem,
  BLink,
  BTooltip,
} from "bootstrap-vue";
import { mapActions } from "vuex";
import Pagination from "@/layouts/components/XmlTvPagination.vue";
import debounce from "lodash.debounce";
import { deleteBannedIp, deleteFromWhitelist } from "@/services/api/admin";
import { apiErrorHandler } from "@/services/utils/util";
import TableSpinner from "@/layouts/components/TableSpinner.vue";
import FilterCheckbox from "@/layouts/components/FilterCheckbox.vue";
import { mapBooleanQueryParameter } from "@/services/utils/util";
import SearchInput from "@/layouts/components/form/SearchInput.vue";
import TableHeaderLabels from "@/layouts/components/TableHeaderLabels.vue";

export default {
  components: {
    BCard,
    BTable,
    BButton,
    BModal,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    Pagination,
    BDropdown,
    BDropdownItem,
    BLink,
    BTooltip,
    TableSpinner,
    FilterCheckbox,
    SearchInput,
    TableHeaderLabels,
  },
  data() {
    return {
      fields: [
        { key: "ip", label: "IP" },
        { key: "is_banned", label: "Banned" },
        { key: "is_whitelisted", label: "Whitelisted" },
        { key: "user_count", label: "Users" },
        { key: "actions", label: "" },
      ],
      payload: {
        ip: null,
        reason: null,
      },
      whitelisting: false,
      loading: false,
    };
  },
  computed: {
    data() {
      return this.$store.getters["adminBanned/getBannedListData"];
    },
    filter() {
      return {
        ...this.$route.query,
        is_banned: mapBooleanQueryParameter(this.$route.query, "is_banned"),
        is_whitelisted: mapBooleanQueryParameter(
          this.$route.query,
          "is_whitelisted"
        ),
        page: this.$route.query?.page ?? 1,
        per_page: this.$route.query?.per_page ?? 10,
        sort_by: this.$route.query?.sort_by ?? "ip",
        order_by: this.$route.query?.order_by ?? "asc",
      };
    },
    meta() {
      return this.$store.getters["adminBanned/getBannedListMeta"];
    },
    stateLoading() {
      return this.$store.getters["adminBanned/isLoading"];
    },
  },
  mounted() {
    this.getBannedIps({ ...this.filter }).catch(console.error);
  },
  methods: {
    ...mapActions({
      getBannedIps: "adminBanned/getBannedIps",
      createBan: "adminBanned/createBan",
      createWhitelisted: "adminBanned/createWhitelisted",
    }),
    openModal(ip = "", whitelisting = null) {
      this.payload.ip = ip;
      if (whitelisting) {
        this.whitelisting = true;
      }
      this.$refs["ban-modal"].show();
    },
    async onSaveBan() {
      if (this.whitelisting) {
        await this.createWhitelisted({ ...this.payload });
      } else {
        await this.createBan({ ...this.payload });
      }
      this.payload.ip = "";
      this.payload.reason = "";
      this.onRefreshPage();
    },
    async revertBan(ip) {
      try {
        await deleteBannedIp(ip);
        this.onRefreshPage();
      } catch (e) {
        apiErrorHandler(e);
      }
    },
    async revertWhitelist(ip) {
      try {
        await deleteFromWhitelist(ip);
        this.onRefreshPage();
      } catch (e) {
        apiErrorHandler(e);
      }
    },
    setFilter(filter = {}) {
      this.filter.page = 1;
      this.$router.replace({ query: { ...this.filter, ...filter } });

      if (filter.search !== undefined) {
        if (filter.search?.length === 0) {
          this.$router.replace({
            query: { ...this.filter, search: undefined },
          });
        }
      }

      this.onRefreshPage();
    },
    changePage(filter = {}) {
      this.$router.replace({ query: { ...this.filter, ...filter } });
      this.onRefreshPage();
    },
    changePerPage(filter = {}) {
      this.filter.page = 1;
      this.$router.replace({ query: { ...this.filter, ...filter } });
      this.onRefreshPage();
    },
    onRefreshPage: debounce(async function () {
      this.loading = true;
      try {
        await this.getBannedIps(this.filter);
      } finally {
        this.loading = false;
      }
    }, 300),
  },
};
</script>

<style></style>
