<template>
  <b-form-group class="ml-auto mt-2 mb-0 cols col-sm-12 col-md-12 col-lg-3">
    <b-form-input
      class="inputs"
      placeholder="Search..."
      v-model="searchInput"
      @input="!isSearchInvalid && $emit('search', searchInput)"
    />
    <small v-if="isSearchInvalid" class="text-warning"
      >Search must be at least 3 characters long</small
    >
  </b-form-group>
</template>

<script>
import { BFormGroup, BFormInput } from "bootstrap-vue";
export default {
  components: {
    BFormGroup,
    BFormInput,
  },
  data() {
    return {
      searchInput: "",
    };
  },
  computed: {
    isSearchInvalid() {
      return this.searchInput.length < 3 && this.searchInput.length > 0;
    },
  },
};
</script>

<style></style>
