<template>
  <div @click="onClickCheckbox">
    <b-form-checkbox
      :checked="value"
      :indeterminate="indeterminate"
      :disabled="disabled"
    >
      {{ this.label }}
    </b-form-checkbox>
  </div>
</template>
<script>
import { BFormCheckbox } from "bootstrap-vue";

export default {
  components: {
    BFormCheckbox,
  },
  props: {
    label: String,
    value: Boolean,
    disabled: Boolean,
  },
  data() {
    return {
      indeterminate: this.value === undefined,
    };
  },
  created() {
    this.$watch(
      () => this.value,
      () => (this.indeterminate = this.value === undefined)
    );
  },
  methods: {
    onClickCheckbox(event) {
      event.preventDefault();
      if (this.disabled) return;
      if (this.value) {
        this.$emit("change", undefined);
      } else if (this.value === undefined) {
        this.$emit("change", false);
      } else {
        this.$emit("change", true);
      }
    },
  },
};
</script>
